import React, { useState } from "react";

const jobListings = [
  {
    id: 1,
    title: 'Electrical Engineering Job',
    category: 'Engineering', // Add category here
    location: 'On-site',
    type: 'Full-time',
    description: 'Urgently hiring Electrical Engineers with at least 2 years experience in Fire Fighting co. In UAE.',
    applyOn: "To Apply this contact on the",
    phoneNumber: "+971502209723",
    postedOn: 'Sep 25, 2024',
  },
  {
    id: 2,
    title: 'Mechanical Engineering Job',
    category: 'Engineering', // Add category here
    location: 'On-site',
    type: 'Full-time',
    description: 'Urgently hiring Mechanical Engineer with at least 2 years experience in Fire Fighting co. In UAE.',
    applyOn: "To Apply this contact on the",
    phoneNumber: "+971502209723",
    postedOn: 'Sep 27, 2024',
  },
  {
    id: 3,
    title: 'Electrical and Mechanical technicians Job',
    category: 'Technicians', // Add category here
    location: 'On-site',
    type: 'Full-time',
    description: 'Urgently hiring Electrical and Mechanical technicians with 2 years experience in Fire Fighting Co.  In UAE',
    applyOn: "To Apply this contact on the",
    phoneNumber: "+971502209723",
    postedOn: 'Sep 30, 2024',
  },
];

const Careers: React.FC = () => {
  const [filter, setFilter] = useState('View all');

  const filterOptions = [
    'View all',
    'Engineering',
    'Technicians',
  ];

  // Filter the job listings based on the selected category
  const filteredJobs = jobListings.filter((job) => {
    if (filter === 'View all') {
      return true; // Show all jobs if 'View all' is selected
    }
    return job.category === filter; // Otherwise, match the job's category with the selected filter
  });

  return (
    <div className="min-h-screen bg-gray-50  ">
         <div
        style={{
          backgroundImage: "url('/assets/teamhero.jpg')",
          height: "50vh",
          width: "100%",
          backgroundPosition: "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      > <span className="w-full h-full flex justify-center items-center text-white font-semibold text-4xl px-4">Join Our Team - Feel free to Apply on +971502209723 </span></div>
      <div className="max-w-6xl mx-auto px-4 mt-10">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900">Engage with Our Growing Team and Make an Impact</h1>
          <p className="text-lg text-gray-500 mt-4">
          We are actively seeking talented and driven individuals to become part of our team. As we expand, we are looking for professionals who thrive in collaborative environments, embrace ownership, and are eager to contribute to meaningful projects. 
          </p>
        </div>

        <div className="flex justify-center mt-10">
          <div className="flex flex-wrap gap-3">
            {filterOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => setFilter(option)}
                className={`${
                  filter === option
                    ? 'bg-black text-white'
                    : 'bg-gray-200 text-gray-700'
                } px-4 py-2 rounded-full font-medium`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        <div className="mt-12 space-y-6 mb-5">
          {filteredJobs.map((job) => (
            <div
              key={job.id}
              className="bg-white shadow rounded-lg p-6 flex justify-between items-center"
            >
              <div>
                <h3 className="text-xl font-bold text-gray-900">{job.title}</h3>
                <p className="text-gray-500 mt-1">{job.description}</p>
                <p className="text-gray-500 mt-1">
                  {job.applyOn} <a href={`tel:${job.phoneNumber}`} className="hover:text-gray-900 text-blue-500">
                    {job.phoneNumber}</a>
                </p>
                <div className="flex space-x-4 mt-3">
                  <span className="text-sm text-gray-600 flex items-center">
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 2a6 6 0 100 12A6 6 0 0010 2zM0 10a10 10 0 1116.32 8.495 7 7 0 01-9.5-9.5A9.951 9.951 0 010 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {job.location}
                  </span>
                  <span className="text-sm text-gray-600 flex items-center">
                    <svg
                      className="w-4 h-4 mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16 8V6a6 6 0 00-12 0v2H3v9h14V8h-1zM6 6a4 4 0 118 0v2H6V6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {job.type}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Careers;
