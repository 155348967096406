import React from "react";
import Navbar from "../../shared/navBar/navbar";
import DetailSection from "../../shared/detailSection/detailSection";

function LandingPage() {
  return (
    <div className="landing-page">
      {/* cover photo */}
      <div
        style={{
          backgroundImage: "url('/assets/banner-1.jpg')",
          height: "80vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full pl-11 md:pl-44 pt-28 text-white">
          <div className="flex leading-none justify-start  text-[40px] md:text-[65px]  font-extrabold ">
            We're here to <br></br> Save Lives and<br></br> Properties
          </div>
          <p className=" font-semibold text-white mt-4 ">
            {" "}
            We save people, animals and place each and every day and we are
            looking <br></br> to give better service in future too.
          </p>
          <a href="/about">
          <div className="bg-[#E78600] hover:opacity-50 w-fit mt-4 p-4 rounded-md cursor-pointer ">
            Discover More
          </div>
          </a>
        </div>
      </div>


      {/* detail section - Think before ot starts */}
      <div className="py-20 md:px-16 ">
        <div className=" text-[30px] md:text-[45px] px-3 font-bold text-[#382E26] justify-center items-center flex ">
          Think of fire before it Starts
        </div>
        <div className="grid  grid-cols-1 mt-3 px-10 md:grid-cols-4 sm:grid-cols-2 gap-10 pt-10">
          <div className="justify-center flex-col items-center flex">
            <img src="/assets/section1image.PNG" className="h-[72px]" alt="Fire Prevention" />
            <p className="font-bold py-3">Fire Preventions</p>
            <div className="flex justify-center items-center w-full h-full text-center">
              The precautions to prevent potentially harmful fires, surviving
              and reducing the damage caused by emergencies.
            </div>
          </div>
          <div className="justify-center flex-col items-center flex">
            <img src="/assets/section2image.PNG" alt="Fire Prevention" />
            <p className="font-bold py-3">Department Information</p>
            <div className="flex justify-center items-center w-full h-full text-center">
            We provide you with practical actions, advice and resources to
            prepare for and cope during a range of emergencies.
            </div>
          </div>
          <div className="justify-center flex-col items-center flex">
            <img
              src="/assets/section3image.PNG"
              className="h-[74px]"
              alt="Fire Prevention"
            />
            <p className="font-bold py-3">Public Education</p>
            <div className="flex justify-center items-center w-full h-full text-center">
            The tools and information for teaching people of all ages about
            the fire and how to make choices regarding safety.
            </div>
          </div>
          <div className="justify-center flex-col items-center flex">
            <img src="/assets/section4image.PNG" className="h-[72px]" alt="Fire Prevention" />
            <p className="font-bold py-3">Stratagic Plan</p>
            <div className="flex justify-center items-center w-full h-full text-center">
            The plan focuses on implementing advanced technologies, improving
            services and resiliency during emergency events.
            </div>
          </div>
        </div>
      </div>

      <DetailSection></DetailSection>

      {/* Section ----- Category */}
      <div className="pb-20 px-16 ">
        <div className=" text-[30px] md:text-[45px] font-bold text-[#382E26] justify-center items-center flex ">
          Categories
        </div>
      </div>

      <div className=""
        style={{
          backgroundImage: "url('/assets/category.PNG')",
          height: "50vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden"
        }}
      >
      </div>


      {/* section 3 - youth FIRE stop prevention */}
      <div className=""
        style={{
          backgroundImage: "url('/assets/image1.jpg')",
          height: "50vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden"
        }}
      >
        <div className="w-full pl-11 md:pl-44 pt-28 text-[#382E26]">
          <div className="flex leading-none justify-start  text-[20px] md:text-[40px]  font-extrabold ">
          Youth Fire Stop Prevention & <br></br>Intervention Program
          </div>
          <p className=" font-semibold text-gray-600 mt-4 ">
            {" "}
            We save people, animals and place each and every day and we are
            looking <br></br> to give better service in future too.
          </p>
          <a href="/about">
          <div className="bg-[#E44426] hover:opacity-60 text-white w-fit mt-4 p-4 rounded-md cursor-pointer ">
            Discover More
          </div>
          </a>
        </div>
      </div>

     
    </div>
  );
}

export default LandingPage;
