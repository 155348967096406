// FireSprinkler.tsx

import React from "react";
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

const Maintenance: React.FC = () => {
  return (
    <div className="w-full justify-center items-center flex-col">
        <div className="h-full w-full justify-center items-center flex font-bold text-3xl py-10 text-[#E78600]">
            Activity of Genius Fire and Safety Equipment
        </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/1.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full mb-16 w-full justify-center items-center flex">
        <img src="/assets/maintenence/2.PNG"></img>
      </div>
      <div className="relative isolate  bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/3.PNG" className="mt-5"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/4.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/5.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/6.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/7.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/8.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/9.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/10.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/11.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/12.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/13.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/14.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/15.PNG"></img>
      </div>
      <div className="relative isolate bg-white h-full w-full justify-center items-center flex">
        <img src="/assets/maintenence/16.PNG"></img>
      </div>
    </div>
  );
};

export default Maintenance;
