// FireSprinkler.tsx

import React from "react";

const FireSprinkler: React.FC = () => {
  return (
    <div className=" p-14 bg-gray-100">
      <h1 className="text-4xl font-bold mb-6 text-[#E78600] flex justify-center">
        Fire Sprinkler Systems
      </h1>
      <p className="text-gray-700 text-lg py-2 ">
        The people behind your fire sprinkler system have one mission:
        protecting lives and property from fire. We offer you a creative,
        common-sense approach to fire sprinkler design, supply, installation,
        service, and inspection. We have the team and expertise to provide you
        with a sprinkler system that meets your fire protection needs.
      </p>
      <img src="/assets/image5.webp"></img>
      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#E78600] ">
          Types of Fire Sprinklers
        </h2>
        <p>
          A sprinkler system is designed to use water to put out a fire. Between
          a fire suppression system and a traditional sprinkler system we have
          your residential, commercial building and industrial. There are four
          different types as follows:
        </p>
        <ul className="list-disc ml-6 text-gray-600">
          <li>
            {" "}
            <strong>Wet pipe systems:</strong> typical application, with water
            in the system's pipes ready to be activated by the opening of fire
            sprinkler heads due to heat.
          </li>
          <li>
            <strong>Dry pipe systems:</strong> this system has air or nitrogen
            gas in the pipes and water is released into the pipes upon
            activation of the sprinkler heads after a slight delay. These
            systems are used in areas where water in pipes can freeze.
          </li>
          <li>
            <strong>Deluge:</strong> activated by smoke or heat detectors,
            deluge equipment releases a dousing flood of water or form in an
            instant through an open-sprinkler, nozzle, or foam generator,
            utilizing an unpressurized piping system. These systems are used in
            areas other than homes and offices where fire can spread quickly.
          </li>
          <li>
            <strong>Pre-Action:</strong> This is a combination of wet and dry
            pipe fire sprinkler systems and are not activated until a fire is
            detected.
          </li>
        </ul>
      </section>
      <section className="mt-8">
        <h2 className="text-4xl font-bold mb-6 text-[#E78600]">
          DESIGN, SUPPLY, INSTALL, SERVICE, AND INSPECT
        </h2>
        <p className="text-gray-700">
          We design your fire sprinkler system to function in any emergency.
          Upon installation, we will perform fire sprinkler testing to check
          functionality and train you on any management needs. To keep your
          system ready for activation, we have assembled a round-the-clock
          technician team to provide immediate service and repair. If you have
          an emergency, we provide 24-hour emergency service for your fire
          protection systems. We also offer fire sprinkler inspection programs
          as required by the latest national codes to our customers. Our
          inspection programs are designed to meet your insurance and industry
          needs be it weekly, monthly, quarterly, bi-annually or yearly
          inspections.
        </p>
      </section>
    </div>
  );
};

export default FireSprinkler;
